<template>
  <router-view/>
</template>

<style>

*, body {
  margin: 0;
  padding: 0;
}
body, #app {
  overflow-x: hidden !important;
  max-width: 100%;
  width: 100%;
}

</style>